
import { Component, Vue } from 'vue-property-decorator';
import { URI_AUTHORIZE_HASH } from '@/api/eob/endpoints';
import { CookieName } from '@/core/cookies';
import { AuthorizationHashResponse, AuthorizationHashParams } from './types';
import { USER_NOTIFY } from '@/store/list';
import { namespace } from 'vuex-class';
import { SET_IS_USER_LOGGED, SET_USER_HASH, SET_USER_TOKEN } from '@/store/auth-store/mutations';

const AuthStore = namespace('AuthStore');
const AppStore = namespace('AppStore');

@Component({
  name: 'AuthorizationHash'
})
export default class AuthorizationHash extends Vue {
  async created (): Promise <void> {
    await this.fetchUserData();
  }

  async fetchUserData (): Promise<void> {
    try {
      const hash = this.$route.params.hash;
      const redirectUri = this.$route.query.redirectUri;
      const requestParams: AuthorizationHashParams = {
        hash,
        redirectUri: typeof redirectUri === 'string' ? redirectUri : '/'
      };
      const response: AuthorizationHashResponse = (await this.$api.post<AuthorizationHashResponse>(URI_AUTHORIZE_HASH, requestParams)).data;
      this.handleSuccessResponse(response, hash);
    } catch (e) {
      this.handleErrorResponse(e);
    }
  }

  @AuthStore.Mutation(SET_USER_TOKEN) setUserToken: any;
  @AuthStore.Mutation(SET_USER_HASH) setUserHash: any;
  @AuthStore.Mutation(SET_IS_USER_LOGGED) setIsUserLogged: any;
  @AppStore.Action(USER_NOTIFY) notify: any;

  handleSuccessResponse (response: AuthorizationHashResponse, hash: string) {
    this.setCookies(response, hash);
    this.setAuthData(response, hash);
    window.location.href = response.redirectUrl;
  }

  handleErrorResponse (e: XMLHttpRequest): void {
    this.notify({ msg: e, type: 'error', permanent: true });
  }

  setCookies (response: AuthorizationHashResponse, hash: string): void {
    this.$cookies.set(CookieName.USER_TOKEN, 'Bearer ' + response.token);
    this.$cookies.set(CookieName.USER_HASH, hash);
  }

  setAuthData (response: AuthorizationHashResponse, hash: string): void {
    this.setIsUserLogged(true);
    this.setUserHash(hash);
    this.setUserToken(response.token);
  }
}
